// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  return {
    routes: {"kuaicj/register-success":{"path":"kuaicj/register-success","id":"kuaicj/register-success","parentId":"@@/global-layout"},"kuaipk/register-success":{"path":"kuaipk/register-success","id":"kuaipk/register-success","parentId":"@@/global-layout"},"kuaixk/register-success":{"path":"kuaixk/register-success","id":"kuaixk/register-success","parentId":"@@/global-layout"},"_old/register-success":{"path":"_old/register-success","id":"_old/register-success","parentId":"@@/global-layout"},"_old/kuaicj-web-open":{"path":"_old/kuaicj-web-open","id":"_old/kuaicj-web-open","parentId":"@@/global-layout"},"_old/kuaipk-web-open":{"path":"_old/kuaipk-web-open","id":"_old/kuaipk-web-open","parentId":"@@/global-layout"},"_old/scan-qrcode":{"path":"_old/scan-qrcode","id":"_old/scan-qrcode","parentId":"@@/global-layout"},"kuaicj/register":{"path":"kuaicj/register","id":"kuaicj/register","parentId":"@@/global-layout"},"kuaipk/register":{"path":"kuaipk/register","id":"kuaipk/register","parentId":"@@/global-layout"},"kuaixk/register":{"path":"kuaixk/register","id":"kuaixk/register","parentId":"@@/global-layout"},"_old/register":{"path":"_old/register","id":"_old/register","parentId":"@@/global-layout"},"_old/invite":{"path":"_old/invite","id":"_old/invite","parentId":"@@/global-layout"},"_old/index":{"path":"_old","id":"_old/index","parentId":"@@/global-layout"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}},
    routeComponents: {
'kuaicj/register-success': React.lazy(() => import(/* webpackChunkName: "src__pages__kuaicj__register-success" */'../../../src/pages/kuaicj/register-success.tsx')),
'kuaipk/register-success': React.lazy(() => import(/* webpackChunkName: "src__pages__kuaipk__register-success" */'../../../src/pages/kuaipk/register-success.tsx')),
'kuaixk/register-success': React.lazy(() => import(/* webpackChunkName: "src__pages__kuaixk__register-success" */'../../../src/pages/kuaixk/register-success.tsx')),
'_old/register-success': React.lazy(() => import(/* webpackChunkName: "src__pages___old__register-success" */'../../../src/pages/_old/register-success.tsx')),
'_old/kuaicj-web-open': React.lazy(() => import(/* webpackChunkName: "src__pages___old__kuaicj-web-open" */'../../../src/pages/_old/kuaicj-web-open.tsx')),
'_old/kuaipk-web-open': React.lazy(() => import(/* webpackChunkName: "src__pages___old__kuaipk-web-open" */'../../../src/pages/_old/kuaipk-web-open.tsx')),
'_old/scan-qrcode': React.lazy(() => import(/* webpackChunkName: "src__pages___old__scan-qrcode" */'../../../src/pages/_old/scan-qrcode.tsx')),
'kuaicj/register': React.lazy(() => import(/* webpackChunkName: "src__pages__kuaicj__register" */'../../../src/pages/kuaicj/register.tsx')),
'kuaipk/register': React.lazy(() => import(/* webpackChunkName: "src__pages__kuaipk__register" */'../../../src/pages/kuaipk/register.tsx')),
'kuaixk/register': React.lazy(() => import(/* webpackChunkName: "src__pages__kuaixk__register" */'../../../src/pages/kuaixk/register.tsx')),
'_old/register': React.lazy(() => import(/* webpackChunkName: "src__pages___old__register" */'../../../src/pages/_old/register.tsx')),
'_old/invite': React.lazy(() => import(/* webpackChunkName: "src__pages___old__invite" */'../../../src/pages/_old/invite.tsx')),
'_old/index': React.lazy(() => import(/* webpackChunkName: "src__pages___old__index" */'../../../src/pages/_old/index.tsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'/home/gitlab-runner/builds/F6CB8YZr/0/clientgroup-v5/react/h5-umi-app/src/layouts/index.tsx')),
},
  };
}
