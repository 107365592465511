import store from '@/store';
import { USER_TOKEN } from '@/config/constants';
import { defaultSerializeQueryArgs } from '@reduxjs/toolkit/query';
import { SerializeQueryArgs } from '@reduxjs/toolkit/dist/query/defaultSerializeQueryArgs';

export const httpStatusMessageMap = new Map<number, string>([
  [400, '请求错误(400)'],
  [401, '未授权，请重新登录(401)'],
  [403, '拒绝访问(403)'],
  [404, '请求出错(404)'],
  [408, '请求超时(408)'],
  [500, '服务器错误(500)'],
  [501, '服务未实现(501)'],
  [502, '网络错误(502)'],
  [503, '服务不可用(503)'],
  [504, '网络超时(504)'],
  [505, 'HTTP版本不受支持(505)'],
]);

export const getErrorMessage = (status: number): string => {
  return httpStatusMessageMap.get(status) || '连接出错';
};

const setHeaderIfNotExist = (headers: Headers, name: string, value: any) => {
  if (headers.has(name)) {
    return;
  }
  headers.set(name, value);
};

export const setAuthHeaders = (headers: Headers) => {
  const token = window.localStorage.getItem(USER_TOKEN);
  const {
    // @ts-ignore
    user: { school, schoolYear, semester, scheduleScheme, timeTableSchema },
  } = store.getState();
  if (token) {
    setHeaderIfNotExist(headers, 'memberToken', token);
  }
  if (school?.id && !headers.has('schoolId')) {
    setHeaderIfNotExist(headers, 'schoolId', school.id);
  }
  if (schoolYear?.id && !headers.has('schoolYearId')) {
    setHeaderIfNotExist(headers, 'schoolYearId', schoolYear.id);
  }
  if (semester?.id) {
    setHeaderIfNotExist(headers, 'semesterId', semester.id);
  }
  if (scheduleScheme?.id) {
    setHeaderIfNotExist(headers, 'schedulingSchemeId', scheduleScheme.id);
  }
  if (timeTableSchema?.id) {
    setHeaderIfNotExist(headers, 'timeTableSchemaId', timeTableSchema.id);
  }
};

export const getAuthHeaders = () => {
  const token = window.localStorage.getItem(USER_TOKEN);
  const {
    // @ts-ignore
    user: { school, schoolYear, semester, scheduleScheme, timeTableSchema },
  } = store.getState();

  return {
    memberToken: token || '',
    schoolId: school?.id || '',
    schoolYearId: schoolYear?.id || '',
    semesterId: semester?.id || '0',
    schedulingSchemeId: scheduleScheme?.id || '',
    timeTableSchemaId: timeTableSchema?.id || '',
  };
};

const isUnAuthCode = (code: number) => {
  return code === 1;
};

const isErrorCode = (code: number) => {
  return code !== 200 && code !== 1 && code !== 4;
};

const isServerError = (response: Response) => {
  return !(response.status >= 200 && response.status < 300);
};

const isTokenInvalid = (code: number, message: string) => {
  return code === 303 && message === 'token过期'
}

export interface CreateValidateStatusConfig {
  onUnAuth?: (defaultAction: () => void) => void;
  onError?: (response: Response, body: any, defaultAction: () => void) => void;
  onTokenInvalid?: (defaultAction: () => void) => void;
}

export const createValidateStatus =
  (config?: CreateValidateStatusConfig) => (response: Response, body: any) => {
    // 服务异常
    if (isServerError(response)) {
      // setFeedbackState({
      //   globalMessageError: {
      //     level: 'error',
      //     message: getErrorMessage(response.status),
      //   },
      // });
      return false;
    }

    // 接口异常
    // errorShowType: 0无提示, 1message错误提示，2modal错误提示, 3modal警告提示, default: 1
    // errorCompProps: 错误组件props
    const { code, msg, errorShowType = 1 } = body || {};

    const defaultTokenInvalidAction = () => {
      // setFeedbackState({
      //   globalModalError: {
      //     level: 'warning',
      //     message: '由于您长时间未登录，缓存的数据已被清除，请及时登录。',
      //     modalConfig: {
      //       okText: '确定'
      //     },
      //     onOk: () => {
      //       localStorage.removeItem(USER_TOKEN)
      //       window.location.reload()
      //     }
      //   },
      // });
    }

    const defaultUnAuthAction = () => {
      window.location.href = '/#/login';
    };

    const defaultOnErrorAction = () => {
      const showType = errorShowType ?? 1;
      const errorMsg = msg || getErrorMessage(response.status);

      if (showType === 1) {
        // setFeedbackState({
        //   globalMessageError: {
        //     level: 'error',
        //     message: errorMsg,
        //   },
        // });
      } else if (showType === 2) {
        // setFeedbackState({
        //   globalModalError: {
        //     level: 'error',
        //     message: errorMsg,
        //   },
        // });
      } else if (showType === 3) {
        // setFeedbackState({
        //   globalModalError: {
        //     level: 'warning',
        //     message: errorMsg,
        //   },
        // });
      }
    };

    if (isTokenInvalid(code, msg)) {
      if (config?.onTokenInvalid) {
        config.onTokenInvalid(defaultTokenInvalidAction);
      } else {
        defaultTokenInvalidAction();
      }
      return false;
    }

    if (isUnAuthCode(code)) {
      if (config?.onUnAuth) {
        config.onUnAuth(defaultUnAuthAction);
      } else {
        defaultOnErrorAction();
      }
      return false;
    }
    if (isErrorCode(code)) {
      if (config?.onError) {
        config.onError(response, body, defaultOnErrorAction);
      } else {
        defaultOnErrorAction();
      }
      return false;
    }

    return true;
  };

// 使用modal.warning进行提示
export const showWarningModalResponseHandler = async (res: Response) => {
  const body = await res.json();
  body.errorShowType = 3;
  return body;
};

// 当头部数据更新时需要更新缓存数据，使用此方法序列化参数
export const serializeQueryArgsWithHeaderArgs: SerializeQueryArgs<any> = ({
  endpointName,
  endpointDefinition,
  queryArgs,
}) => {
  return defaultSerializeQueryArgs({
    endpointName,
    endpointDefinition,
    queryArgs: {
      ...(queryArgs || {}),
      // @ts-ignore
      scheduleSchemeId: store.getState().user.scheduleScheme?.id || '',
    },
  });
};

// 录入信息保存时返回的错误信息，需要用户确认操作
export const showConfirmForSaveTimeTableInfo = (errorMsg: string = '') => {
  return errorMsg.indexOf('直接覆盖') > -1;
};

// 课时任课，设置单双周，合班及分层操作时，如果课程有设置条件或者排课，需要用户确认操作
export const showConfirmForCourseClassSettings = (code: number) => {
  return code === 303
};
