import React, { ReactNode, useContext } from 'react';
import { message, Modal } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import { ModalStaticFunctions } from 'antd/es/modal/confirm';

const MessageContext = React.createContext<MessageInstance | null>(null);

export const useMessage = () => {
  const message = useContext(MessageContext);
  return message as MessageInstance;
};

export const MessageProvider = ({ children }: { children: ReactNode }) => {
  const [api, contextHolder] = message.useMessage({
    duration: 2,
    maxCount: 1,
    rtl: false,
  });

  return (
    <MessageContext.Provider value={api}>
      {contextHolder}
      {children}
    </MessageContext.Provider>
  );
};

const ModalContext = React.createContext<Omit<
  ModalStaticFunctions,
  'warn'
> | null>(null);

export const useModal = () => {
  const modal = useContext(ModalContext);
  return modal as Omit<ModalStaticFunctions, 'warn'>;
};

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [api, contextHolder] = Modal.useModal();

  return (
    <ModalContext.Provider value={api}>
      {contextHolder}
      {children}
    </ModalContext.Provider>
  );
};
