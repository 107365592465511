import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import base from '@/services/base';

export const store = configureStore({
  reducer: {
    [base.reducerPath]: base.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: false, // 由于存在大块数据（400kb），禁用serializable中间件
    });
    return defaultMiddleware.concat(base.middleware);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type RootStateNamespace = keyof RootState;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
