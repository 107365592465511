export const isPro = process.env.DEPLOY_ENV === 'production';
export const isTest = process.env.DEPLOY_ENV === 'test';
const isPreview = process.env.DEPLOY_ENV === 'preview';

export const DEPLOY_DIR = isPro ? '/' : isTest ? '/h5' : '/';

// 后端服务域名
export const SERVER_URL = isPro
  ? 'https://api.basejy.com'
  : 'https://tencent.test-env.basejy.com/v5api';

export const APP_URL = isPro
  ? 'https://mobile.basejy.com'
  : 'https://tencent.test-env.basejy.com/h5';

// 微信公众号appid
export const WX_GZH_APPID = isPro
  ? 'wxae050b3b7db880a5'
  : isPreview
  ? 'wx99bc4493f44c706f'
  : 'wxe2088458302747d3';

// 微信小程序appid
export const WX_MP_APP_ID = 'wx2b5870e22df01aff';

// 后端服务前缀
export const BASE_SYSTEM_USER = '/base-system-user';
export const SCHOOL_SERVICE = '/base-school';
export const TEACHER_SERVICE = '/base-school-teacher';
export const SUPPORT_SERVICE = '/ttfs-support';
export const SCHOOL_COURSE = '/base-school-course';
export const BASE_SCHEDULING = '/base-scheduling';
export const BASE_SCHOOL_COURSE = '/base-school-course';
export const BASE_SCHOOL_TEACHER = '/base-school-teacher';
export const BASE_SCHOOL = '/base-school';
export const SCHEDULING_ENGINE = '/schedule/engine/scheduleproject';
export const BASE_PARSE = '/base-parse';
export const BASE_USER_BEHAVIOR = '/base-user-behavior';

// 前端localStorage相关Key
export const USER_TOKEN = 'h5_user_token'; // token
export const VISITOR_FROM = 'h5_visitor_from'
